import { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import MainTitle from '../../../components/MainTitle'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useAuth from '../../../hooks/useAuth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faPlusCircle,
  faTrash,
  faUpload,
  faArrowsUpDownLeftRight,
} from '@fortawesome/free-solid-svg-icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

function CreateQuestionnaire() {
  const axios = useAxiosPrivate()
  const { id } = useParams()
  const { setShowLoader, createInfo } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const initialData = location.state?.initialData

  const [questionnaireTitle, setQuestionnaireTitle] = useState('')
  const [questionnaireTotal, setQuestionnaireTotal] = useState(false)
  const questionnaireTypes = [
    { type: 'text', name: 'Short Answer', readableType: 'Short Answer' },
    { type: 'text-area', name: 'Paragraph', readableType: 'Paragraph' },
    { type: 'date', name: 'Date', readableType: 'Date' },
    { type: 'time', name: 'Time', readableType: 'Time' },
    { type: 'number', name: 'Number', readableType: 'Number', tallyable: true },
    { type: 'email', name: 'Email', readableType: 'Email' },
    {
      type: 'telephone',
      name: 'Telephone Number',
      readableType: 'Telephone Number',
    },
    {
      type: 'checkbox',
      name: 'Multiple Choice',
      readableType: 'Multiple Choice',
      hasOptions: true,
      tallyable: true,
    },
    {
      type: 'radio',
      name: 'Single Choice',
      readableType: 'Single Choice',
      hasOptions: true,
      tallyable: true,
    },
    {
      type: 'range',
      name: 'Linear Scale',
      readableType: 'Linear Scale',
      hasOptions: true,
      tallyable: true,
    },
    {
      type: 'select',
      name: 'Drop Down',
      readableType: 'Drop Down',
      hasOptions: true,
      tallyable: true,
    },
  ]
  const [questions, setQuestions] = useState([
    {
      question: '',
      type: questionnaireTypes[0],
      description: '',
      required: false,
      tally: false,
      options: {},
      optionValues: {},
      answerGiven: '',
    },
  ])

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  useEffect(() => {
    if (initialData) {
      const parsedData = initialData.content
      setQuestionnaireTitle(parsedData.title)
      setQuestionnaireTotal(parsedData.tallyable)
      setQuestions(parsedData.questions)
    }
  }, [initialData])

  const submit = async () => {
    setShowLoader(true)
    try {
      const data = {
        id: initialData?.id,
        questions: questions.map((item) => ({
          ...item,
          options: Object.assign({}, item.options),
          optionValues: item.tally
            ? { ...item.optionValues }
            : Object.keys(item.optionValues).reduce(
                (acc, key) => ({ ...acc, [key]: '0' }),
                {}
              ),
        })),
        title: questionnaireTitle,
        total: '',
        tallyable: questionnaireTotal,
        clinic: id,
      }
      const response = await axios.post(`/questionnaires/create`, data)
      if (response.data.result) {
        setShowLoader(false)
        navigate(`/clinics/${id}`)
      }
    } catch (error) {
      createInfo('error', 'Unable to create questionnaire, please try again')
    }
  }

  const toggleTotal = () => {
    setQuestionnaireTotal(!questionnaireTotal)
  }

  const removeQuestion = (index) => {
    setQuestions(questions.filter((_, i) => i !== index))
  }

  const questionRequired = (index) => {
    setQuestions(
      questions.map((q, i) =>
        i === index ? { ...q, required: !q.required } : q
      )
    )
  }

  const includeInTally = (index) => {
    setQuestions(
      questions.map((q, i) => (i === index ? { ...q, tally: !q.tally } : q))
    )
  }

  const changeDescription = (index, value) => {
    setQuestions(
      questions.map((q, i) => (i === index ? { ...q, description: value } : q))
    )
  }

  const changeTitle = (value) => {
    setQuestionnaireTitle(value)
  }

  const changeQuestion = (index, value) => {
    setQuestions(
      questions.map((q, i) => (i === index ? { ...q, question: value } : q))
    )
  }

  const changeType = (index, option) => {
    setQuestions(
      questions.map((q, i) => (i === index ? { ...q, type: option } : q))
    )
  }

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        question: '',
        type: questionnaireTypes[0],
        description: '',
        required: false,
        tally: false,
        options: [],
        optionValues: [],
        answerGiven: '',
      },
    ])
  }

  const addOption = (questionIndex, type = null, optionIndex = null) => {
    setQuestions(
      questions.map((q, i) => {
        if (i === questionIndex) {
          const options = { ...q.options }
          const optionValues = { ...q.optionValues }
          if (type === 'linear') {
            options[options.length] = 'Min'
            options[options.length + 1] = 'Max'
          } else {
            options[Object.keys(options).length + 1] = ''
            optionValues[Object.keys(optionValues).length + 1] = ''
          }
          return { ...q, options, optionValues }
        }
        return q
      })
    )
  }

  const removeOption = (questionIndex, optionIndex) => {
    setQuestions(
      questions.map((q, i) => {
        if (i === questionIndex) {
          const options = { ...q.options }
          const optionValues = { ...q.optionValues }
          delete options[optionIndex + 1]
          delete optionValues[optionIndex + 1]
          return { ...q, options, optionValues }
        }
        return q
      })
    )
  }

  const updateOptionAnswer = (value, questionIndex, optionKey) => {
    setQuestions(
      questions.map((q, i) => {
        if (i === questionIndex) {
          const options = { ...q.options, [optionKey]: value }
          return { ...q, options }
        }
        return q
      })
    )
  }

  const updateOptionValue = (value, questionIndex, optionKey) => {
    setQuestions(
      questions.map((q, i) => {
        if (i === questionIndex) {
          const optionValues = { ...q.optionValues, [optionKey]: value }
          return { ...q, optionValues }
        }
        return q
      })
    )
  }

  const updateLinear = (value, questionIndex, type) => {
    setQuestions(
      questions.map((q, i) => {
        if (i === questionIndex) {
          const options = { ...q.options, [type.toLowerCase()]: value }
          return { ...q, options }
        }
        return q
      })
    )
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const items = reorder(
      questions,
      result.source.index,
      result.destination.index
    )

    setQuestions(items)
  }

  const RenderHtml = ({ index }) => {
    const question = questions[index]
    const questionType = question.type?.readableType

    switch (questionType) {
      case 'Drop Down':
      case 'Multiple Choice':
      case 'Single Choice':
        return (
          <div className="space-y-4">
            {question.options &&
              Object.entries(question.options).map(
                ([optionKey, option], optionIndex) => (
                  <div
                    className="flex items-center space-x-6"
                    key={optionIndex}
                  >
                    <div className="flex items-center ">
                      <div className="bg-slate-300 rounded-l-md border px-4 py-2">
                        Option {optionIndex + 1}
                      </div>
                      <input
                        type="text"
                        required
                        defaultValue={option || ''}
                        placeholder="Answer"
                        onBlur={(e) =>
                          updateOptionAnswer(e.target.value, index, optionKey)
                        }
                        className={`border ${
                          question.tally && questionType === 'Single Choice'
                            ? 'rounded-none'
                            : 'rounded-r-md'
                        } text-black px-4 py-2`}
                      />
                      {question.tally && questionType === 'Single Choice' && (
                        <input
                          type="text"
                          required
                          defaultValue={question.optionValues[optionKey] || ''}
                          placeholder="Value"
                          onBlur={(e) =>
                            updateOptionValue(e.target.value, index, optionKey)
                          }
                          className="border rounded-r-md text-black px-4 py-2"
                        />
                      )}
                    </div>
                    <div className="flex space-x-2">
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        className="h-5 w-5 text-blue-600 cursor-pointer"
                        onClick={() => addOption(index, null, optionIndex + 1)}
                      />
                      {optionIndex !== 0 && (
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="h-5 w-5 text-red-600 cursor-pointer"
                          onClick={() => removeOption(index, optionIndex)}
                        />
                      )}
                    </div>
                  </div>
                )
              )}
          </div>
        )
      case 'Linear Scale':
        return (
          <div className="flex space-x-4">
            <div className="flex items-center space-x-6">
              <div className="flex items-center ">
                <div className="bg-slate-300 rounded-l-md border px-4 py-2">
                  Min
                </div>
                <input
                  type="number"
                  defaultValue={question.options?.min}
                  placeholder="Min"
                  onChange={(e) => updateLinear(e.target.value, index, 'Min')}
                  className="border rounded-r-md text-black px-4 py-2"
                />
              </div>
            </div>
            <div className="flex items-center space-x-6">
              <div className="flex items-center ">
                <div className="bg-slate-300 rounded-l-md border px-4 py-2">
                  Max
                </div>
                <input
                  type="number"
                  defaultValue={question.options?.max}
                  placeholder="Max"
                  onChange={(e) => updateLinear(e.target.value, index, 'Max')}
                  className="border rounded-r-md text-black px-4 py-2"
                />
              </div>
            </div>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <section>
      <MainTitle
        title={`${initialData?.id ? 'Edit' : 'Create'} Questionnaire`}
        backButton
      />

      <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8 mt-8">
        <div className="mb-3">
          <input
            type="text"
            value={questionnaireTitle}
            onChange={(e) => changeTitle(e.target.value)}
            placeholder="Title"
            required
            className="p-2 border w-full rounded-md"
          />
        </div>
        <div className="px-2">
          <input
            title="required"
            type="checkbox"
            defaultChecked={questionnaireTotal}
            onChange={toggleTotal}
            className="mr-2 cursor-pointer"
          />
          <label>Total Answers</label>
          <p className="text-slate-500">
            Assign point values to questions and allow auto-grading.
          </p>
        </div>
        <hr className="mb-6 mt-6" />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-questions">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="w-full border rounded-md"
              >
                {questions.map((item, index) => (
                  <Draggable
                    key={`question-${index}`}
                    draggableId={`question-${index}`}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className="mb-3 pb-4 pt-8 pr-4 pl-8 relative"
                        key={index}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <FontAwesomeIcon
                          icon={faArrowsUpDownLeftRight}
                          className="p-2 bg-gray-200 rounded-md absolute top-0 left-0"
                        />
                        <div className="mb-3 w-full flex justify-between">
                          <div className="w-2/3 pr-4">
                            <input
                              type="text"
                              placeholder="Question"
                              value={item.question}
                              onChange={(e) =>
                                changeQuestion(index, e.target.value)
                              }
                              className="border rounded-md px-2 py-3 w-full"
                            />
                          </div>
                          <div className="border rounded-md w-1/3">
                            <select
                              className="w-full px-2"
                              value={JSON.stringify(item.type)}
                              onChange={(e) => {
                                let selectedType = JSON.parse(e.target.value)
                                changeType(index, selectedType)
                                if (
                                  (selectedType.readableType === 'Drop Down' ||
                                    selectedType.readableType ===
                                      'Multiple Choice' ||
                                    selectedType.readableType ===
                                      'Single Choice') &&
                                  Object.keys(item.options).length <= 0
                                ) {
                                  addOption(index, null, 0)
                                }
                                if (
                                  selectedType.readableType ===
                                    'Linear Scale' &&
                                  Object.keys(item.options).length <= 0
                                ) {
                                  addOption(index, 'Linear', 0)
                                }
                              }}
                            >
                              {questionnaireTypes.map((type, idx) => (
                                <option key={idx} value={JSON.stringify(type)}>
                                  {type.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="mb-3">
                          <input
                            type="text"
                            placeholder="Description"
                            value={item.description}
                            onChange={(e) =>
                              changeDescription(index, e.target.value)
                            }
                            className="border rounded-md w-full p-2"
                          />
                        </div>
                        <RenderHtml index={index} />
                        <div className="flex float-right">
                          {questions.length > 1 && (
                            <div className="px-2 cursor-pointer">
                              <span
                                title="remove"
                                className="text-red-500"
                                onClick={() => removeQuestion(index)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </span>
                            </div>
                          )}
                          <div className="px-2 cursor-pointer">
                            <input
                              title="tally"
                              type="checkbox"
                              checked={item.tally}
                              onChange={() => includeInTally(index)}
                              className="mr-2"
                            />
                            <label>Include in Tally</label>
                          </div>
                          <div className="px-2 cursor-pointer">
                            <input
                              title="required"
                              type="checkbox"
                              defaultChecked={item.required}
                              onChange={() => questionRequired(index)}
                              className="mr-2"
                            />
                            <label>Required</label>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="flex mt-2 justify-between">
          <button
            className="flex items-center border border-blue-500 rounded-md px-4 py-2 text-blue-500 hover:bg-blue-500 hover:text-white"
            onClick={addQuestion}
          >
            <FontAwesomeIcon icon={faPlus} className="pr-2" />{' '}
            <span>Add Question</span>
          </button>
          <button
            type="submit"
            className="btn flex items-center"
            onClick={submit}
          >
            <FontAwesomeIcon icon={faUpload} className="pr-2" />{' '}
            <span>Save</span>
          </button>
        </div>
      </section>
    </section>
  )
}

export default CreateQuestionnaire
