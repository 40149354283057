const GenerateQuestion = ({
  data,
  index,
  setIsFormSave,
  questionnaireResponse,
  hasChanged,
  setQuestionnaireResponse
}) => {
  const convertQuestionOptionsToSelectBoxOptions = (options) => {
    return Object.keys(options).map((key) => ({
      value: options[key],
      text: options[key],
    }))
  }

  const updateTotal = () => {
    const total = questionnaireResponse.questions.reduce((acc, question) => {
      const answer = question.answerGiven
      const answerIndex = Object.values(question.options).indexOf(answer)
      const answerValue = Object.values(question.optionValues)[answerIndex]
      // if (!question.tally) {
      //   return acc
      // }

      if (typeof answerValue === 'string') {
        acc += parseInt(answerValue, 10) || 0
      } else if (typeof answerValue === 'number') {
        acc += answerValue
      } else if (Array.isArray(answerValue)) {
        acc += answerValue.reduce((subAcc, cur) => {
          subAcc += parseInt(cur, 10)
          return subAcc
        }, 0)
      }

      return acc
    }, 0)
    setQuestionnaireResponse({ ...questionnaireResponse, total: total })
  }

  const question = {
    index,
    type: data.type.type,
    label: data.question,
    description: data.description,
    rangeOptions: data.options,
    options: convertQuestionOptionsToSelectBoxOptions(
      data.options,
      data.optionValues
    ),
    required: data.required,
    min: parseInt(data.min, 10),
    max: parseInt(data.max, 10),
    maxRows: 30,
    optionsCount: parseInt(data.optionsCount, 10),
    tally: false,
  }

  switch (data.type.type) {
    case 'text':
      question.component = (
        <div>
          <input
            required={question.required}
            name={`question_${index}`}
            id={questionnaireResponse[index]}
            onChange={(e) => {
              questionnaireResponse.questions[index].answerGiven =
                e.target.value
              hasChanged = true
              console.log(hasChanged)
            }}
            type="text"
            defaultValue={questionnaireResponse.questions[index].answerGiven}
            className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
          />
        </div>
      )
      break
    case 'date':
      question.component = (
        <div>
          <input
            required={question.required}
            name={`question_${index}`}
            id={questionnaireResponse[index]}
            onChange={(e) => {
              questionnaireResponse.questions[index].answerGiven =
                e.target.value
              hasChanged = true
              console.log(hasChanged)
            }}
            type="date"
            defaultValue={questionnaireResponse.questions[index].answerGiven}
            className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
          />
        </div>
      )
      break
    case 'time':
      question.component = (
        <div>
          <input
            required={question.required}
            name={`question_${index}`}
            id={questionnaireResponse[index]}
            onChange={(e) => {
              questionnaireResponse.questions[index].answerGiven =
                e.target.value
              hasChanged = true
              console.log(hasChanged)
            }}
            type="time"
            defaultValue={questionnaireResponse.questions[index].answerGiven}
            className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
          />
        </div>
      )
      break
    case 'number':
      question.component = (
        <div>
          <input
            required={question.required}
            name={`question_${index}`}
            id={questionnaireResponse[index]}
            onChange={(e) => {
              questionnaireResponse.questions[index].answerGiven =
                e.target.value
              hasChanged = true
            }}
            type="number"
            defaultValue={questionnaireResponse.questions[index].answerGiven}
            className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
          />
        </div>
      )
      break
    case 'email':
      question.component = (
        <div>
          <input
            required={question.required}
            name={`question_${index}`}
            id={questionnaireResponse[index]}
            onChange={(e) => {
              questionnaireResponse.questions[index].answerGiven =
                e.target.value
              hasChanged = true
            }}
            type="email"
            defaultValue={questionnaireResponse.questions[index].answerGiven}
            className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
          />
        </div>
      )
      break
    case 'telephone':
      question.component = (
        <div>
          <input
            required={question.required}
            name={`question_${index}`}
            id={questionnaireResponse[index]}
            onChange={(e) => {
              questionnaireResponse.questions[index].answerGiven =
                e.target.value
              hasChanged = true
            }}
            type="tel"
            defaultValue={questionnaireResponse.questions[index].answerGiven}
            className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
          />
        </div>
      )
      break
    case 'text-area':
      question.component = (
        <div>
          <textarea
            defaultValue={questionnaireResponse.questions[index].answerGiven}
            type="text"
            required={question.required}
            name={`question_${index}`}
            id={questionnaireResponse[index]}
            onChange={(e) => {
              questionnaireResponse.questions[index].answerGiven =
                e.target.value
              hasChanged = true
            }}
            className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
          />
        </div>
      )
      break
    case 'range':
      question.component = (
        <div>
          <input
            required={question.required}
            name={`question_${index}`}
            id={questionnaireResponse[index]}
            onChange={(e) => {
              questionnaireResponse.questions[index].answerGiven =
                e.target.value
              hasChanged = true
            }}
            type="range"
            defaultValue={questionnaireResponse.questions[index].answerGiven}
            className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
          />
        </div>
      )
      break
    case 'checkbox':
      if (questionnaireResponse.questions[index].answerGiven == null) {
        questionnaireResponse.questions[index].answerGiven = []
      }
      if (questionnaireResponse.questions[index].answerGiven.length <= 0) {
        questionnaireResponse.questions[index].answerGiven = []
      }
      question.component = question?.options.map((option, checkIndex) => {
        return (
          <div className="items-center flex">
            <input
              type="checkbox"
              id={`${index}_check_${checkIndex}`}
              value={option.value}
              name={`question_${index}`}
              defaultChecked={
                questionnaireResponse.questions[index].answerGiven[checkIndex]
              }
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
              onChange={(e) => {
                questionnaireResponse.questions[index].answerGiven[checkIndex] =
                  e.target.checked
                hasChanged = true
                updateTotal()
              }}
            />

            <label
              htmlFor={`${index}_check_${checkIndex}`}
              className="ml-2 mr-4 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              {option.text}
            </label>
          </div>
        )
      })
      break
    case 'radio':
      question.component = question?.options.map((option, radioIndex) => {
        return (
          <div className="items-center flex">
            <input
              type="radio"
              required={question.required}
              id={`${index}_radio_${radioIndex}`}
              name={`question_${index}`}
              value={option.value}
              defaultChecked={
                questionnaireResponse.questions[index].answerGiven ===
                option.value
              }
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
              onChange={(e) => {
                questionnaireResponse.questions[index].answerGiven =
                  e.target.value
                hasChanged = true
                updateTotal()
              }}
            />
            <label
              htmlFor={`${index}_radio_${radioIndex}`}
              className="ml-2 mr-4 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              {option.text}
            </label>
          </div>
        )
      })
      break
    case 'select':
      question.component = (
        <div>
          <select
            defaultValue={
              questionnaireResponse.questions[index].answerGiven ?? ''
            }
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required={question.required}
            onChange={(e) => {
              questionnaireResponse.questions[index].answerGiven =
                e.target.value
              hasChanged = true
              updateTotal()
            }}
          >
            <option disabled value={''}>
              Select an Option
            </option>
            {question?.options.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.text}
                </option>
              )
            })}
          </select>
        </div>
      )
      break
    default:
      break
  }
  return (
    <div className="mb-3">
      <h2 className="font-medium mb-1">{question.label}</h2>
      {question.component}
      <p className="text-sm mt-1 font-gray-500">{question.description}</p>
    </div>
  )
}

export default GenerateQuestion
